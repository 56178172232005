// import React, { useState, useEffect, useRef } from 'react';
// import { Modal, Button, Form } from 'react-bootstrap';

// const OrderList = () => {
//   const [items, setItems] = useState([]);
//   const [phoneNumber, setPhoneNumber] = useState('');
//   const [name, setName] = useState('');
//   const [showModal, setShowModal] = useState(false);
//   const socket = useRef(null);

//   useEffect(() => {
//     // Initialize WebSocket connection
//     socket.current = new WebSocket("ws://your-websocket-url");

//     socket.current.onopen = () => console.log("WebSocket connected in OrderList");

//     socket.current.onmessage = (event) => {
//       const data = JSON.parse(event.data);
//       if (data.type === "cartUpdate") {
//         setItems(data.items); // Update items in OrderList when receiving data from WebSocket
//       }
//     };

//     socket.current.onclose = () => console.log("WebSocket disconnected in OrderList");

//     return () => {
//       if (socket.current) socket.current.close();
//     };
//   }, []);

//   return (
//     <div>
//       <h2>Cart</h2>
//       {items.length === 0 ? (
//         <p>No items in the cart.</p>
//       ) : (
//         items.map((item) => (
//           <div key={item.id}>
//             <p>{item.name}: {item.quantity}</p>
//           </div>
//         ))
//       )}

//       <Modal show={showModal} onHide={() => setShowModal(false)}>
//         <Modal.Header closeButton>
//           <Modal.Title>Enter Your Details</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <Form>
//             <Form.Group>
//               <Form.Label>Phone Number</Form.Label>
//               <Form.Control
//                 type="text"
//                 value={phoneNumber}
//                 onChange={(e) => setPhoneNumber(e.target.value)}
//               />
//             </Form.Group>
//             <Form.Group>
//               <Form.Label>Name</Form.Label>
//               <Form.Control
//                 type="text"
//                 value={name}
//                 onChange={(e) => setName(e.target.value)}
//               />
//             </Form.Group>
//           </Form>
//         </Modal.Body>
//         <Modal.Footer>
//           <Button onClick={() => console.log("Checkout initiated")}>Checkout</Button>
//         </Modal.Footer>
//       </Modal>
//     </div>
//   );
// };

// export default OrderList;

import React, { useState, useEffect, useContext } from "react";
import { WebSocketContext } from "../context/WebSocketContext";

const OrderList = () => {
  const { stompClient, connected, connectWebSocket } = useContext(WebSocketContext); // Ensure connectWebSocket is available
  const [items, setItems] = useState([]);

  useEffect(() => {
    let subscription;

    // Ensure the WebSocket connection is established before subscribing
    const initializeSubscription = () => {
      if (connected && stompClient.current) {
        console.log("Subscribing to /topic/orderStatus");

        subscription = stompClient.current.subscribe("/topic/orderStatus", (message) => {
          console.log("Received cart update message:", message.body);
          try {
            const data = JSON.parse(message.body);
            setItems(data.items); // Update items based on received data
          } catch (error) {
            console.error("Error parsing message body:", error);
          }
        });

        if (subscription) {
          console.log(`Successfully subscribed with id: ${subscription.id}`);
        }
      } else {
        console.log("WebSocket is not connected. Attempting to connect...");
        connectWebSocket(); // Initiates connection if not already connected
      }
    };

    // Wait for the WebSocket connection and then initialize the subscription
    if (!connected) {
      console.log("Waiting for WebSocket connection...");
      const connectionInterval = setInterval(() => {
        if (connected) {
          clearInterval(connectionInterval);
          initializeSubscription();
        }
      }, 500); // Check connection every 500ms
    } else {
      initializeSubscription();
    }

    // Clean up the subscription when the component unmounts
    return () => {
      if (subscription) {
        console.log("Unsubscribing from /topic/orderStatus");
        subscription.unsubscribe();
      }
    };
  }, [connected, stompClient, connectWebSocket]);

  return (
    <div>
      <h2>Order List</h2>
      {items.length === 0 ? (
        <p>No items in the cart.</p>
      ) : (
        items.map((item) => (
          <div key={item.id}>
            <p>{item.name}: {item.quantity}</p>
          </div>
        ))
      )}
    </div>
  );
};

export default OrderList;


