// import React, { createContext, useRef, useEffect, useState } from "react";
// import { Stomp } from "@stomp/stompjs";
// import SockJS from "sockjs-client";

// export const WebSocketContext = createContext();

// export const WebSocketProvider = ({ children }) => {
//   const stompClient = useRef(null);
//   const [connected, setConnected] = useState(false);

//   useEffect(() => {
//     const socket = new SockJS("https://api.awsconsultant.in/apis/qrscanner-0.0.1-SNAPSHOT/ws-orders");
//     stompClient.current = Stomp.over(socket);
//     stompClient.current.debug = () => {};

//     stompClient.current.connect({}, () => {
//       console.log("Connected to WebSocket");
//       setConnected(true);
//     }, (error) => {
//       console.error("Error connecting to WebSocket", error);
//       setConnected(false);
//     });

//     return () => {
//       if (stompClient.current) {
//         stompClient.current.disconnect(() => console.log("Disconnected from WebSocket"));
//       }
//     };
//   }, []);

//   return (
//     <WebSocketContext.Provider value={{ stompClient, connected }}>
//       {children}
//     </WebSocketContext.Provider>
//   );
// };

import React, { createContext, useRef, useState } from "react";
import { Stomp } from "@stomp/stompjs";
import SockJS from "sockjs-client";

export const WebSocketContext = createContext();

export const WebSocketProvider = ({ children }) => {
  const stompClient = useRef(null);
  const [connected, setConnected] = useState(false);

  const connectWebSocket = () => {
    if (connected) return; // Avoid reconnecting if already connected

    const socket = new SockJS("https://api.awsconsultant.in/apis/qrscanner-0.0.1-SNAPSHOT/ws-orders");
    stompClient.current = Stomp.over(socket);
    stompClient.current.debug = () => {}; // Disable debug logs

    stompClient.current.connect(
      {},
      () => {
        console.log("WebSocket connected");
        setConnected(true);
      },
      (error) => {
        console.error("WebSocket connection error:", error);
        setConnected(false);
      }
    );
  };

  const disconnectWebSocket = () => {
    if (stompClient.current && connected) {
      stompClient.current.disconnect(() => {
        console.log("WebSocket disconnected");
        setConnected(false);
      });
    }
  };

  return (
    <WebSocketContext.Provider value={{ stompClient, connected, connectWebSocket, disconnectWebSocket }}>
      {children}
    </WebSocketContext.Provider>
  );
};

