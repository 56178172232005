// import React, { useState, useEffect, useContext, useRef } from "react";
// import axios from "axios";
// import { Modal, Button, Form } from "react-bootstrap";
// import { LuIndianRupee } from "react-icons/lu";
// import { FaMinus, FaPlus } from "react-icons/fa";
// import { useNavigate } from "react-router-dom";
// import logo from "../datas/foodlogo.jpg";
// import { IoCart } from "react-icons/io5";
// import GridLoader from "react-spinners/GridLoader";
// import Card from "react-bootstrap/Card";
// import confetti from "canvas-confetti";
// import { WebSocketContext } from "../context/WebSocketContext";

// const AdvisorMenu = () => {
//   const { stompClient, connected } = useContext(WebSocketContext);
//   const [menu, setMenu] = useState([]);
//   const [items, setItems] = useState([]);
//   const [showModal, setShowModal] = useState(true);
//   const [phoneNumber, setPhoneNumber] = useState("");
//   const [loading, setLoading] = useState(true);
//   const navigate = useNavigate();
//   const id = localStorage.getItem("businessId");

//   useEffect(() => {
//     const getMenu = async () => {
//       try {
//         const response = await axios.get(
//           `${process.env.REACT_APP_API_URL}api/businesses/${id}/categories/with-items`
//         );
//         setMenu(response.data);
//         setLoading(false);
//       } catch (error) {
//         console.error("Error fetching menu:", error);
//         setLoading(false);
//       }
//     };

//     getMenu();

//     let subscription;

//     // Only subscribe if WebSocket is connected and stompClient is initialized
//     if (connected && stompClient.current) {
//       console.log("Attempting to subscribe to /topic/orderStatus");

//       subscription = stompClient.current.subscribe(
//         "/topic/orderStatus",
//         (message) => {
//           console.log("Received message on /topic/orderStatus:", message.body); // Log the broadcast message
//           try {
       
//             setItems((prevItems) => [...prevItems, message.body]);
//           } catch (err) {
//             console.error("Error parsing message body", err);
//           }
//         }
//       );



//       if (subscription && subscription.id) {
//         console.log(`Successfully subscribed with id: ${subscription.id}`);
//       } else {
//         console.log("Subscription failed or returned no id");
//       }
//     } else {
//       console.log("Not connected or stompClient not initialized.");
//     }

//     return () => {
//       if (subscription) {
//         console.log("Unsubscribing from /topic/orderStatus");
//         subscription.unsubscribe();
//       }
//     };
//   }, [id, connected, stompClient]);

//   const handlePhoneSubmit = () => {
//     if (phoneNumber.length < 10) {
//       alert("Please enter a valid mobile number.");
//     } else {
//       setShowModal(false);
//     }
//   };

//   const handleAddItemClick = (item) => {
//     if (!connected) {
//       console.error("STOMP client is not connected yet");
//       return;
//     }

//     const updatedItems = [...items];
//     const existingItem = updatedItems.find((i) => i.id === item.id);

//     if (existingItem) {
//       existingItem.quantity += 1;
//     } else {
//       updatedItems.push({ ...item, quantity: 1 });
//     }
//     setItems(updatedItems);

//     stompClient.current.send(
//       "/topic/orderStatus",
//       {},
//       JSON.stringify({ items: updatedItems })
//     );

//     console.log("Sent updated items:", updatedItems);
//     // stompClient.current.send("/app/orderStatus", {}, JSON.stringify({ items: updatedItems }));

//     //     console.log("Sent updated items:", updatedItems);
//     confetti();
//   };

//   const handleRemoveItemClick = (item) => {
//     if (!connected) {
//       console.error("STOMP client is not connected yet");
//       return;
//     }

//     const updatedItems = items
//       .map((i) => (i.id === item.id ? { ...i, quantity: i.quantity - 1 } : i))
//       .filter((i) => i.quantity > 0);

//     setItems(updatedItems);

//     stompClient.current.send(
//       "/topic/orderStatus",
//       {},
//       JSON.stringify({ items: updatedItems })
//     );

//     console.log("Sent updated items after removal:", updatedItems);
//   };

//   const callOrder = async () => {
//     const orderDetailItems = [
//       { item: { id: 2 }, quantity: 4 },
//       { item: { id: 3 }, quantity: 1 },
//     ];
//     const payload = {
//       customerName: "abc",
//       businessId: id,
//       phoneNumber: `${phoneNumber}`,
//       orderDetailItems,
//     };
//     const response = await axios.post(
//       `${process.env.REACT_APP_API_URL}orders`,
//       payload
//     );
//     console.log(response);
//   };

//   if (loading) {
//     return (
//       <div className="loader-overlay">
//         <GridLoader size={50} color="black" />
//       </div>
//     );
//   }

//   return (
//     <div>
//       <Modal show={showModal} onHide={() => setShowModal(true)} centered>
//         <Modal.Header closeButton>
//           <Modal.Title>Enter Mobile Number</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <Form>
//             <Form.Group controlId="formPhoneNumber">
//               <Form.Label>Mobile Number</Form.Label>
//               <Form.Control
//                 type="text"
//                 placeholder="Enter your mobile number"
//                 value={phoneNumber}
//                 onChange={(e) => setPhoneNumber(e.target.value)}
//               />
//             </Form.Group>
//           </Form>
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="primary" onClick={handlePhoneSubmit}>
//             Submit
//           </Button>
//         </Modal.Footer>
//       </Modal>

//       {menu.length === 0 ? (
//         <Card style={{ width: "20rem", margin: "20px" }}>
//           <Card.Body>
//             <Card.Title>No Menu To Show</Card.Title>
//             <Card.Text>
//               Please add a menu for this business to display items.
//             </Card.Text>
//           </Card.Body>
//         </Card>
//       ) : (
//         <div className="menu_main">
//           {menu.map((category, index) => (
//             <div key={index} className="menu_category">
//               {category.items.map((item) => (
//                 <div key={item.id} className="menu_item">
//                   <div className="menu_desc">
//                     <h6>{item.name}</h6>
//                     <div className="btnprice">
//                       <h6>
//                         <LuIndianRupee /> {item.price}
//                       </h6>
//                       {items.some((cartItem) => cartItem.id === item.id) ? (
//                         <div className="btnqtymenu">
//                           <button
//                             type="button"
//                             className="plusminusbuttonmenu"
//                             onClick={() => handleAddItemClick(item)}
//                             disabled={!connected} // Disable if not connected
//                           >
//                             <FaPlus />
//                           </button>
//                           <span>
//                             {items.find((cartItem) => cartItem.id === item.id)
//                               ?.quantity || "Add"}
//                           </span>
//                           {items.find((cartItem) => cartItem.id === item.id)
//                             ?.quantity > 0 && (
//                             <button
//                               type="button"
//                               className="plusminusbuttonmenu"
//                               onClick={() => handleRemoveItemClick(item)}
//                               disabled={!connected} // Disable if not connected
//                             >
//                               <FaMinus />
//                             </button>
//                           )}
//                         </div>
//                       ) : (
//                         <button
//                           type="button"
//                           className="btn btn-dark"
//                           onClick={() => handleAddItemClick(item)}
//                           disabled={!connected} // Disable if not connected
//                         >
//                           Add
//                         </button>
//                       )}
//                     </div>
//                   </div>
//                   <img className="menu_image" src={logo} alt={item.name} />
//                 </div>
//               ))}
//             </div>
//           ))}
//         </div>
//       )}

//       <div className="menu_footer" onClick={callOrder}>
//         <h3>
//           Check Out <IoCart /> {items.length}
//         </h3>
//       </div>
//     </div>
//   );
// };

// export default AdvisorMenu;

// // import React, { useState, useEffect, useRef } from "react";
// import { Stomp } from "@stomp/stompjs";
// import SockJS from "sockjs-client";
// const AdvisorMenu = () => {
//     const [messages, setMessages] = useState([]);
//     const stompClient = useRef(null);

//     useEffect(() => {
//       console.log("Initializing WebSocket connection...");

//       // Initialize SockJS and STOMP client
//       const socket = new SockJS("https://api.awsconsultant.in/apis/qrscanner-0.0.1-SNAPSHOT/ws-orders"); // Adjust URL as needed
//       stompClient.current = Stomp.over(socket);

//       // Disable debug messages for STOMP unless you want them
//       stompClient.current.debug = (msg) => console.log("STOMP Debug:", msg);

//       // Connect to the WebSocket server
//       stompClient.current.connect(
//         {},
//         (frame) => {
//           console.log("Connected to WebSocket server at /ws-simple");
//           console.log("Frame received on connect:", frame);

//           // Subscribe to the topic and log the subscription ID if successful
//           const subscription = stompClient.current.subscribe(
//             "/topic/orderStatus",
//             (message) => {
//               console.log("Received message on /topic/orderStatus:", message.body);
//               setMessages((prevMessages) => [...prevMessages, message.body]);
//             }
//           );

//           // Log subscription ID or error
//           if (subscription && subscription.id) {
//             console.log(`Successfully subscribed to /topic/orderStatus with ID: ${subscription.id}`);
//           } else {
//             console.error("Subscription to /topic/orderStatus failed or has no ID");
//           }
//         },
//         (error) => {
//           console.error("Error connecting to WebSocket:", error);
//         }
//       );

//       // Cleanup: Disconnect on component unmount
//       return () => {
//         if (stompClient.current) {
//           stompClient.current.disconnect(() => {
//             console.log("Disconnected from WebSocket");
//           });
//         }
//       };
//     }, []);

//     return (
//       <div>
//         <h2>Simple Message Broadcasts</h2>
//         <ul>
//           {messages.map((msg, index) => (
//             <li key={index}>{msg}</li>
//           ))}
//         </ul>
//       </div>
//     );
//   };

//   export default AdvisorMenu;


import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Modal, Button, Form } from "react-bootstrap";
import { LuIndianRupee } from "react-icons/lu";
import { FaMinus, FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import logo from "../datas/foodlogo.jpg";
import { IoCart } from "react-icons/io5";
import GridLoader from "react-spinners/GridLoader";
import Card from "react-bootstrap/Card";
import confetti from "canvas-confetti";
import { WebSocketContext } from "../context/WebSocketContext";

const AdvisorMenu = () => {
    const { stompClient, connected, connectWebSocket } = useContext(WebSocketContext);
    const [menu, setMenu] = useState([]);
    const [items, setItems] = useState([]);
    const [showModal, setShowModal] = useState(true);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [loading, setLoading] = useState(true);
  
    const id = localStorage.getItem("businessId");
  
    useEffect(() => {
      const getMenu = async () => {
        try {
          console.log("Fetching menu...");
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}api/businesses/${id}/categories/with-items`
          );
          setMenu(response.data);
          setLoading(false);
          console.log("Menu fetched successfully:", response.data);
        } catch (error) {
          console.error("Error fetching menu:", error);
          setLoading(false);
        }
      };
  
      if (connected && stompClient.current) {
        console.log("Subscribing to /topic/orderStatus...");
        const subscription = stompClient.current.subscribe("/topic/orderStatus", (message) => {
          console.log("Message received on /topic/orderStatus:", message.body);
          const data = JSON.parse(message.body);
          setItems((prevItems) => [...prevItems, data]);
        });
  
        return () => {
          console.log("Unsubscribing from /topic/orderStatus...");
          subscription.unsubscribe();
        };
      }
  
      getMenu();
    }, [connected, stompClient, id]);
  
    const handlePhoneSubmit = () => {
      if (phoneNumber.length < 10) {
        alert("Please enter a valid mobile number.");
      } else {
        setShowModal(false);
        console.log("Phone number submitted. Connecting WebSocket...");
        connectWebSocket();
      }
    };
  
    const handleAddItemClick = (item) => {
      if (!connected) {
        console.error("STOMP client is not connected yet");
        return;
      }
  
      console.log("Adding item to cart:", item);
      const updatedItems = [...items];
      const existingItem = updatedItems.find((i) => i.id === item.id);
  
      if (existingItem) {
        existingItem.quantity += 1;
      } else {
        updatedItems.push({ ...item, quantity: 1 });
      }
      setItems(updatedItems);
  
      stompClient.current.send(
        "/topic/orderStatus",
        {},
        JSON.stringify({ items: updatedItems })
      );
      console.log("Sent updated items to WebSocket:", updatedItems);
    };
  if (loading) {
    return (
      <div className="loader-overlay">
        <GridLoader size={50} color="black" />
      </div>
    );
  }

  return (
    <div>
      <Modal show={showModal} onHide={() => setShowModal(true)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Enter Mobile Number</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formPhoneNumber">
              <Form.Label>Mobile Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your mobile number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handlePhoneSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      {menu.length === 0 ? (
        <Card style={{ width: "20rem", margin: "20px" }}>
          <Card.Body>
            <Card.Title>No Menu To Show</Card.Title>
            <Card.Text>
              Please add a menu for this business to display items.
            </Card.Text>
          </Card.Body>
        </Card>
      ) : (
        <div className="menu_main">
          {menu.map((category, index) => (
            <div key={index} className="menu_category">
              {category.items.map((item) => (
                <div key={item.id} className="menu_item">
                  <div className="menu_desc">
                    <h6>{item.name}</h6>
                    <div className="btnprice">
                      <h6>
                        <LuIndianRupee /> {item.price}
                      </h6>
                      {items.some((cartItem) => cartItem.id === item.id) ? (
                        <div className="btnqtymenu">
                          <button
                            type="button"
                            className="plusminusbuttonmenu"
                            onClick={() => handleAddItemClick(item)}
                            disabled={!connected}
                          >
                            <FaPlus />
                          </button>
                          <span>
                            {items.find((cartItem) => cartItem.id === item.id)
                              ?.quantity || "Add"}
                          </span>
                        </div>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-dark"
                          onClick={() => handleAddItemClick(item)}
                          disabled={!connected}
                        >
                          Add
                        </button>
                      )}
                    </div>
                  </div>
                  <img className="menu_image" src={logo} alt={item.name} />
                </div>
              ))}
            </div>
          ))}
        </div>
      )}

      <div
        className="menu_footer"
        
      >
        <h3>
          Check Out <IoCart /> {items.length}
        </h3>
      </div>
    </div>
  );
};

export default AdvisorMenu;
