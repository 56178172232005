import React, { useEffect, useRef } from 'react'
import Fluid from 'webgl-fluid';
export default function FoodSimulation() {
    const canvas = useRef(null)
    useEffect(function () {
      let c = canvas.current
      Fluid(c)
    }, [])
  return (
    <div >
    <canvas ref={canvas} style={{width: '100%', height: '85vh'}}></canvas>
    </div>
  )
}
