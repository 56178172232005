import React, { useState, useEffect, useRef, useContext } from "react";
import axios from "axios";
import { LuIndianRupee } from "react-icons/lu";
import { CartContext } from "../context/CartContext";
import { IoMdArrowDropright } from "react-icons/io";
import "./business.css";
import { FaMinus, FaPlus } from 'react-icons/fa';
import { useNavigate, useParams } from "react-router-dom";
import logo from "../datas/foodlogo.jpg";
import { IoCart } from "react-icons/io5";
import GridLoader from "react-spinners/GridLoader";
import Card from "react-bootstrap/Card";
import confetti from "canvas-confetti";

var pumpkin = confetti.shapeFromPath({
  path: "M449.4 142c-5 0-10 .3-15 1a183 183 0 0 0-66.9-19.1V87.5a17.5 17.5 0 1 0-35 0v36.4a183 183 0 0 0-67 19c-4.9-.6-9.9-1-14.8-1C170.3 142 105 219.6 105 315s65.3 173 145.7 173c5 0 10-.3 14.8-1a184.7 184.7 0 0 0 169 0c4.9.7 9.9 1 14.9 1 80.3 0 145.6-77.6 145.6-173s-65.3-173-145.7-173zm-220 138 27.4-40.4a11.6 11.6 0 0 1 16.4-2.7l54.7 40.3a11.3 11.3 0 0 1-7 20.3H239a11.3 11.3 0 0 1-9.6-17.5zM444 383.8l-43.7 17.5a17.7 17.7 0 0 1-13 0l-37.3-15-37.2 15a17.8 17.8 0 0 1-13 0L256 383.8a17.5 17.5 0 0 1 13-32.6l37.3 15 37.2-15c4.2-1.6 8.8-1.6 13 0l37.3 15 37.2-15a17.5 17.5 0 0 1 13 32.6zm17-86.3h-82a11.3 11.3 0 0 1-6.9-20.4l54.7-40.3a11.6 11.6 0 0 1 16.4 2.8l27.4 40.4a11.3 11.3 0 0 1-9.6 17.5z",
  matrix: [
    0.020491803278688523, 0, 0, 0.020491803278688523, -7.172131147540983,
    -5.9016393442622945,
  ],
});
// tree shape from https://thenounproject.com/icon/pine-tree-1471679/
var tree = confetti.shapeFromPath({
  path: "M120 240c-41,14 -91,18 -120,1 29,-10 57,-22 81,-40 -18,2 -37,3 -55,-3 25,-14 48,-30 66,-51 -11,5 -26,8 -45,7 20,-14 40,-30 57,-49 -13,1 -26,2 -38,-1 18,-11 35,-25 51,-43 -13,3 -24,5 -35,6 21,-19 40,-41 53,-67 14,26 32,48 54,67 -11,-1 -23,-3 -35,-6 15,18 32,32 51,43 -13,3 -26,2 -38,1 17,19 36,35 56,49 -19,1 -33,-2 -45,-7 19,21 42,37 67,51 -19,6 -37,5 -56,3 25,18 53,30 82,40 -30,17 -79,13 -120,-1l0 41 -31 0 0 -41z",
  matrix: [
    0.03597122302158273, 0, 0, 0.03597122302158273, -4.856115107913669,
    -5.071942446043165,
  ],
});
// heart shape from https://thenounproject.com/icon/heart-1545381/
var heart = confetti.shapeFromPath({
  path: "M167 72c19,-38 37,-56 75,-56 42,0 76,33 76,75 0,76 -76,151 -151,227 -76,-76 -151,-151 -151,-227 0,-42 33,-75 75,-75 38,0 57,18 76,56z",
  matrix: [
    0.03333333333333333, 0, 0, 0.03333333333333333, -5.566666666666666,
    -5.533333333333333,
  ],
});

var defaults = {
  scalar: 2,
  spread: 180,
  particleCount: 10,
  origin: { y: -0.1 },
  startVelocity: -35,
};



const Business = () => {
  const { addItem, removeItem, items, cartcount } = useContext(CartContext);
  const [menu, setMenu] = useState([]);
  const [activeCategory, setActiveCategory] = useState(0);
  const [animatedItems, setAnimatedItems] = useState({});
  const [animateFooter, setAnimateFooter] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { id } = useParams();
  const categoryRefs = useRef([]);
  const itemRefs = useRef([]);
  const sliderRef = useRef(null);

  useEffect(() => {
    const getMenu = async () => {
      const tokenId = localStorage.getItem("tokenId");
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}api/businesses/${id}/categories/with-items`
        );
        const data = response.data;
        setMenu(data);
        localStorage.setItem("businessId", id); // Set the business ID in local storage
      } catch (error) {
        console.error("Error fetching menu:", error);
      } finally {
        setLoading(false);
      }
    };
    getMenu();
  }, [id]);

  useEffect(() => {
    const handleScroll = () => {
      const categoryElements = categoryRefs.current;
      categoryElements.forEach((categoryElement, index) => {
        const rect = categoryElement.getBoundingClientRect();
        if (
          rect.top <= window.innerHeight / 2 &&
          rect.bottom >= window.innerHeight / 2
        ) {
          setActiveCategory(index);
          scrollSliderToActiveCategory(index);
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollSliderToActiveCategory = (index) => {
    const sliderElement = sliderRef.current;
    const activeButton = sliderElement.children[index];
    const offset =
      activeButton.offsetLeft -
      sliderElement.offsetWidth / 2 +
      activeButton.offsetWidth / 2;
    sliderElement.scrollTo({ left: offset, behavior: "smooth" });
  };

  const scrollToCategory = (index) => {
    const categoryElement = categoryRefs.current[index];
    const firstItemElement =
      itemRefs.current[index] && itemRefs.current[index][0];
    if (categoryElement && firstItemElement) {
      window.scrollTo({
        top: firstItemElement.offsetTop - 100,
        behavior: "smooth",
      });
      setActiveCategory(index);
      scrollSliderToActiveCategory(index);
    }
  };

  const handleAddItemClick = (item) => {
    const businessId = localStorage.getItem("businessId");
    addItem({ ...item, businessId });
    setAnimatedItems((prev) => ({ ...prev, [item.id]: true }));
    setTimeout(() => {
      setAnimatedItems((prev) => ({ ...prev, [item.id]: false }));
    }, 1000);
    setAnimateFooter(true);
    setTimeout(() => {
      setAnimateFooter(false);
    }, 1000);

    setTimeout(shoot, 0);
  };

  const handleRemoveItemClick = (item) => {
    removeItem(item);
  };

  //celebration confetti
  const shoot = () => {
    confetti({
      ...defaults,
      shapes: [pumpkin],
      colors: ["#ff9a00", "#ff7400", "#ff4d00"],
    });
    confetti({
      ...defaults,
      shapes: [tree],
      colors: ["#8d960f", "#be0f10", "#445404"],
    });
    confetti({
      ...defaults,
      shapes: [heart],
      colors: ["#f93963", "#a10864", "#ee0b93"],
    });
  };

  if (loading) {
    return (
      <div className="loader-overlay">
        <GridLoader size={50} color="black" />
      </div>
    );
  }

  return (
    <div>
      {menu.length === 0 ? (
        <Card style={{ width: "20rem", margin: "20px" }}>
          <Card.Body>
            <Card.Title>No Menu To Show</Card.Title>
            <Card.Text>
              Please Add A Menu In This Business To Show Menu List
            </Card.Text>
          </Card.Body>
        </Card>
      ) : (
        <div className="menu_main">
          {menu.map((category, index) => (
            <div
              key={index}
              className="menu_category"
              ref={(el) => (categoryRefs.current[index] = el)}
            >
              {category.items.map((item, itemIndex) => (
                <div
                  key={item.id}
                  className={`menu_item ${
                    animatedItems[item.id] ? "animate-add" : ""
                  }`}
                  ref={(el) => {
                    if (!itemRefs.current[index]) itemRefs.current[index] = [];
                    itemRefs.current[index][itemIndex] = el;
                  }}
                >
                  <div className="menu_desc">
                    <h6>{item.name}</h6>
                    <div className="btnprice">
                      <h6>
                        <LuIndianRupee />
                        {item.price}
                      </h6>
                      {items.some((cartItem) => cartItem.id === item.id) ? (
                      <div className="btnqtymenu">
                        <button
                          type="button"
                          className="plusminusbuttonmenu"
                          onClick={() => handleAddItemClick(item)}
                        >
                          <FaPlus/>
                        </button>
                     
                          <>
                            <span>
                              {items.find((cartItem) => cartItem.id === item.id)
                                .quantity || 'Add'}
                            </span>
                            {items.find((cartItem) => cartItem.id === item.id)
                              .quantity > 0 && (
                              <button
                                type='button'
                                className='plusminusbuttonmenu'
                                onClick={() => handleRemoveItemClick(item)}
                              >
                                <FaMinus />
                              </button>
                            )}
                          </>
                      
                      </div>  ) : (
                          <button
                          type="button"
                          className="btn btn-dark"
                          onClick={() => handleAddItemClick(item)}
                        >
                          Add
                        </button>
                      )}
                    </div>
                  </div>
                  <div>
                    <img className="menu_image" src={logo} alt={item.name} />
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
      <div className="horizontal_slider" ref={sliderRef}>
        {menu.map((category, index) => (
          <button
            key={index}
            className={`slider_button ${
              activeCategory === index ? "active" : ""
            }`}
            onClick={() => scrollToCategory(index)}
          >
            {category.category.name}
          </button>
        ))}
      </div>
      <div
        className={`menu_footer ${animateFooter ? "animate-footer" : ""}`}
        onClick={() => navigate("/cart")}
      >
        <h3>
          Check Out{" "}
          <span>
            <IoCart /> {cartcount}{" "}
          </span>
        </h3>
        <h3>
          <IoMdArrowDropright />
        </h3>
      </div>
    </div>
  );
};

export default Business;
