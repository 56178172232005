

import React, { useEffect, useState } from "react";
import "./currentOrder.css";
import { FaMinus, FaPlus } from "react-icons/fa";
import axios from "axios";

const CurrentOrders = () => {
  const [datas, setDatas] = useState([]);

  const recalculateOrderTotals = (order) => {
    const totalAmount = order.orderDetailItems.reduce(
      (sum, item) => sum + item.price * item.quantity,
      0
    );
    const sgst = totalAmount * 0.09; // Assuming 9% SGST, adjust as needed
    const cgst = totalAmount * 0.09; // Assuming 9% CGST, adjust as needed
    const total = totalAmount + sgst + cgst;

    return {
      ...order,
      totalAmount,
      sgst,
      cgst,
      total,
    };
  };

  const updateItemQuantity = (orderId, itemIndex, delta) => {
    setDatas((prevDatas) => {
      return prevDatas.map((order) => {
        if (order.id === orderId) {
          const updatedItems = [...order.orderDetailItems];
          if (!updatedItems[itemIndex].notAvailable) {
            const newQuantity = updatedItems[itemIndex].quantity + delta;
            if (newQuantity > 0) {
              updatedItems[itemIndex] = {
                ...updatedItems[itemIndex],
                quantity: newQuantity,
              };
            } else {
              updatedItems.splice(itemIndex, 1);
            }
          }
          const updatedOrder = {
            ...order,
            orderDetailItems: updatedItems,
          };
          return recalculateOrderTotals(updatedOrder);
        }
        return order;
      });
    });
  };

  const handleStatusUpdate = async (order, statusEndpoint) => {
    const itemsToSend = order.orderDetailItems.filter(
      (item) => item.quantity > 0 && !item.notAvailable
    );
    const dataToSend = {
      orderId: order.id,
      orderNumber: order.orderNumber,
      items: itemsToSend.map((item) => ({
        itemId: item.item.id,
        name: item.item.name,
        quantity: item.quantity,
        price: item.price,
      })),
    };

    try {
      alert(`Are You sure You Want To ${statusEndpoint} This Order`)
      console.log(dataToSend);
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}orders/${dataToSend.orderId}/${statusEndpoint}`
      );
      console.log("API response:", response.data);
      CurrentOrder();
    } catch (error) {
      console.error("API call error:", error);
    }
  };

  const getLastSegmentOfOrderNumber = (orderNumber) => {
    const segments = orderNumber.split("-");
    return segments[segments.length - 1];
  };

  useEffect(() => {
    CurrentOrder();
  }, []);

  const CurrentOrder = async () => {
    try {
      const businessId = localStorage.getItem("businessId");
      const responseGet = await axios.get(
        `${process.env.REACT_APP_API_URL}orders/current/${businessId}`
      );
      setDatas(responseGet.data);
    } catch (error) {
      console.error(error);
    }
  };

  const renderButtons = (order) => {
    switch (order.currentStatusDisplayName) {
      case "Created":
        return (
          <>
            <button
              type="button"
              style={{ padding: "10px 30px" }}
              className="btn btn-success"
              onClick={() => handleStatusUpdate(order, "accept")}
            >
              Accept
            </button>
            <button
              type="button"
              style={{ padding: "10px 30px" }}
              className="btn btn-danger"
            >
              Reject
            </button>
          </>
        );
      case "Accepted":
        return (
          <button
            type="button"
            style={{ padding: "10px 30px" }}
            className="btn btn-success"
            onClick={() => handleStatusUpdate(order, "completed")}
          >
            Complete
          </button>
        );
      case "Completed":
        return (
          <button
            type="button"
            style={{ padding: "10px 30px" }}
            className="btn btn-success"
            onClick={() => handleStatusUpdate(order, "paid")}
          >
            Paid
          </button>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <div className="main_2_div">
        {datas.length === 0 ? (
          <p>No orders available.</p>
        ) : (
          <ul className="cart">
            {datas.map((order) => (
              <div className="current-orders-category" key={order.id}>
                <h6>
                  Order#: {getLastSegmentOfOrderNumber(order.orderNumber)} , ({" "}
                  {order.currentStatusDisplayName} )
                </h6>
                {order.orderDetailItems.map((item, itemIndex) => (
                  <div className="c-o-cartitems" key={itemIndex}>
                    <div className="price_per_qty">
                      <p>{item.item.name}</p>
                      {order.currentStatusDisplayName === "Created" ? (
                        <div className="btnqty">
                          <button
                            className="c-o-plusminusbutton"
                            onClick={() =>
                              updateItemQuantity(order.id, itemIndex, -1)
                            }
                          >
                            <FaMinus />
                          </button>
                          <span>{item.quantity}</span>
                          <button
                            className="c-o-plusminusbutton"
                            onClick={() =>
                              updateItemQuantity(order.id, itemIndex, 1)
                            }
                          >
                            <FaPlus />
                          </button>
                        </div>
                      ) : (
                        <div className="btnqtyelse">
                          <span>{item.quantity}</span>
                        </div>
                      )}
                    </div>
                    {item.notAvailable && (
                      <p style={{ color: "red" }}>Not Available</p>
                    )}
                  </div>
                ))}
                {order.currentStatusDisplayName === "Completed" && (
                  <div className="order-total">
                    <p>Total Amount: Rs{order.totalAmount.toFixed(2)}</p>
                  </div>
                )}
                <div className="accept-reject-button">
                  {renderButtons(order)}
                </div>
              </div>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default CurrentOrders;

