

import React, { useRef, useState } from "react";
import axios from "axios";
import { GridLoader } from "react-spinners"; // Import GridLoader from react-spinners
import "./upload.css";
import { useNavigate } from "react-router-dom";


const UploadMenu = () => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false); // Loading state for file upload

  const fileInputRef = useRef(null);
  const navigate = useNavigate();

  // Input change handler
  const handleFileChange = (e) => {
    setFiles([...e.target.files]);
  };

  // Submit handler for both add new menu and replace menu
  const handleSubmit = async (e, endpoint) => {
    e.preventDefault();
    const businessId = localStorage.getItem("businessId");
    setLoading(true); // Set loading state to true before making the request

    const formData = new FormData();
    files.forEach((file) => {
      formData.append(`files`, file);
      formData.append("businessId", businessId);
    });

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}sales/${endpoint}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        console.log("Files uploaded successfully");
        alert("Files uploaded successfully");
        navigate("/business_dashboard");
      } else {
        console.error("Failed to upload files");
        alert("Failed to upload files.Please try again later...");
      }
    } catch (error) {
      console.error("Error uploading files:", error);
      alert("Error uploading files:", error);
    } finally {
      setLoading(false); // Reset loading state after request completes
    }
  };

  return (
    <div className="main-div">
      <form className="form">
        <div className="main-text">
          <h1 style={{ marginTop: ".5rem" }}>Upload Menu</h1>
          <div>
            <div className="mb-3">
              <label htmlFor="formFile" className="form-label">
                Upload image of Menu
              </label>
              <input
                accept="image/*"
                ref={fileInputRef}
                onChange={handleFileChange}
                className="form-control"
                type="file"
                id="formFile"
                multiple
              />
              <small id="emailHelp" className="form-text text-muted">
                Please upload a photo in jpg or jpeg format of your menu.
              </small>
            </div>
          </div>

          <div style={{ paddingTop: "1rem" }}>
            <button
              className="btn-submit"
              type="submit"
              onClick={(e) => handleSubmit(e, "addImages")}
            >
              Add new menu
            </button>
          </div>
          <div style={{ paddingTop: "1rem" }}>
            <button
              className="btn-submit"
              type="submit"
              onClick={(e) => handleSubmit(e, "replaceMenu")}
            >
              Replace menu
            </button>
          </div>

          {/* Conditionally render GridLoader based on loading state */}
          {loading && (
            <div className="loader-overlay">
              <GridLoader size={50} color="black" />
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default UploadMenu; // Ensure default export


