import React, { useState } from 'react';
import axios from 'axios';
import { ComposedChart, XAxis, YAxis, Tooltip, Legend, CartesianGrid, Area, Bar, Line } from 'recharts';
import { GridLoader } from 'react-spinners';
import { LiaRupeeSignSolid } from "react-icons/lia";

const DataForDateDash = () => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [totalSumSale,setTotalSumSale] = useState(0);

  const addDateHandler = async (e) => {
    e.preventDefault();
    const bId = localStorage.getItem("businessId");
    setLoading(true);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}orders/business/${bId}/total-sales`,
        {
          params: {
            startDate,
            endDate,
          },
        }
      );
      const responseData = response.data;

      // The response data is already in the correct format, so we can use it directly
      setData(response.data);
      setTotalSumSale(responseData.reduce((sum, item) => sum + item.totalSales, 0));
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  return (
    <div>
      <form style={{ margin: "1rem" }}>
        <div className="mb-3">
          <label htmlFor="startDate" className="form-label">
            Start Date
          </label>
          <input
            type="date"
            className="form-control"
            id="startDate"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="endDate" className="form-label">
            End Date
          </label>
          <input
            type="date"
            className="form-control"
            id="endDate"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>

        <button
          type="submit"
          className="btn-get-started-main"
          onClick={addDateHandler}
        >
          Search
        </button>
      </form>

      {loading ? (
        <GridLoader size={50} />
      ) : (
        <div>
          <h1 style={{ fontWeight: "700", margin: "1rem", color: "#400D4E", fontSize: "30px" }}>
            Tap & Order
          </h1>
          <div className="af_main">
          <div
          className="card"
          style={{
            width: "16rem",
            marginTop: "1.5rem",
            backgroundColor: "#94c875",
            color: "white",
          }}
        >
          <div className="card-body">
            <h4 className="card-text">Total Sale Price</h4>
            <h1 className="card-title"><LiaRupeeSignSolid style={{paddingBottom:"5px"}}/> {totalSumSale} </h1>
          </div>
        </div>
          </div>
          <div style={{ width: "90%", padding: "1rem", margin: "1.5rem", backgroundColor: "iceblue", border: "1px solid black", borderRadius: "10px" }}>
            <h2>Business Sale</h2>
            <div style={{ overflowX: "scroll" }}>
              <ComposedChart width={730} height={250} data={data}>
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <CartesianGrid stroke="#f5f5f5" />
                <Area type="monotone" dataKey="totalSales" fill="#8884d8" stroke="#8884d8" />
                <Bar dataKey="totalSales" barSize={15} fill="#413ea0" />
              
              </ComposedChart>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DataForDateDash;