import React, { createContext, useState, useEffect, useMemo } from 'react';

const CartContext = createContext();

const CartProvider = ({ children }) => {
  const [items, setItems] = useState(() => {
    const savedItems = localStorage.getItem('cartItems');
    return savedItems ? JSON.parse(savedItems) : [];
  });

  const [cartcount, setCartcount] = useState(() => {
    const savedCount = localStorage.getItem('cartCount');
    return savedCount ? parseInt(savedCount, 10) : 0;
  });

  const [businessName, setBusinessName] = useState(() => {
    const savedBusinessName = localStorage.getItem('businessName');
    return savedBusinessName || '';
  });

  const addItem = (item) => {
    const existingBusinessId = localStorage.getItem('businessId');
    const newBusinessId = item.businessId;

    if (existingBusinessId && existingBusinessId !== newBusinessId) {
      clearCart();
    }

    setItems((prevItems) => {
      const existingItem = prevItems.find((i) => i.id === item.id);
      if (existingItem) {
        return prevItems.map((i) =>
          i.id === item.id ? { ...i, quantity: i.quantity + 1 } : i
        );
      } else {
        return [...prevItems, { ...item, quantity: 1 }];
      }
    });
  };

  const removeItem = (item) => {
    setItems((prevItems) => {
      const existingItem = prevItems.find((i) => i.id === item.id);
      if (existingItem.quantity === 1) {
        return prevItems.filter((i) => i.id !== item.id);
      } else {
        return prevItems.map((i) =>
          i.id === item.id ? { ...i, quantity: i.quantity - 1 } : i
        );
      }
    });
  };

  const clearCart = () => {
    setItems([]);
    setCartcount(0);
    setBusinessName('');
    localStorage.removeItem('cartItems');
    localStorage.removeItem('cartCount');
    localStorage.removeItem('businessName');
  };

  const updateBusinessName = (name) => {
    setBusinessName(name);
    localStorage.setItem('businessName', name);
  };

  useEffect(() => {
    const count = items.reduce((total, item) => total + item.quantity, 0);
    setCartcount(count);
    localStorage.setItem('cartItems', JSON.stringify(items));
    localStorage.setItem('cartCount', count.toString());
  }, [items]);

  const totalPrice = useMemo(() => items.reduce((total, item) => total + item.price * item.quantity, 0), [items]);

  const value = useMemo(() => ({
    items,
    addItem,
    removeItem,
    cartcount,
    totalPrice,
    clearCart,
    businessName,
    updateBusinessName
  }), [items, cartcount, totalPrice, businessName]);

  return (
    <CartContext.Provider value={value}>
      {children}
    </CartContext.Provider>
  );
};

export { CartContext, CartProvider };