import React, { useState,useContext } from "react";
import { NavLink } from "react-router-dom";
import "./NavBar.css";
import { CodeIcon, HamburgetMenuClose, HamburgetMenuOpen } from "./Icons";
import { CartContext } from "../context/CartContext";
import logo from '../datas/tapLogo.png'
function NavBar() {
  const [click, setClick] = useState(false);
  
  const { addItem, cartcount,   clearCart,businessName,updateBusinessName } = useContext(CartContext);


  const handleClick = () => setClick(!click);
  return (
    <>
      <nav className="navbar">
        <div className="nav-container">
          <NavLink exact  className="nav-logo">
        <img style={{width:"125px",height:"auto"}}src={logo}/>
        <h6 style={{color:"#FFC72C", width:"7rem"}}>{businessName}</h6>
          </NavLink>


          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <NavLink
                exact
                to="/"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/business/1"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Menu
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/uploadmenu"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Add Menu
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/getbusiness"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Business List
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/cart"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Cart
              </NavLink>
            </li>
          </ul>
          <div className="nav-icon" onClick={handleClick}>
            {/* <i className={click ? "fas fa-times" : "fas fa-bars"}></i> */}

            {!click ? (
              <span className="icon">
                <HamburgetMenuOpen />{" "}
              </span>
            ) : (
              <span className="icon">
                <HamburgetMenuClose />
              </span>
            )}
          </div>
        </div>
      </nav>
    </>
  );
}

export default NavBar;