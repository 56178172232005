import React from 'react';
import Card from 'react-bootstrap/Card';
import { NavLink, useNavigate } from 'react-router-dom';

const BusinessDashboard = () => {
    const navigate = useNavigate();
    const id = localStorage.getItem("businessId");
  return (
    <div>
         <Card type = 'button' style={{ width: '90%',margin:"20px",backgroundColor:"#eff0f7" }} >
      <Card.Body>
      
   <NavLink style={{textDecoration:"none"}} to={`/business/${id}`}>  <Card.Subtitle style={{textAlign:"center" , textDecoration:"none"}} className="mb-2 text-muted">View My Menu</Card.Subtitle></NavLink> 
     

      </Card.Body>
    </Card>
         <Card style={{ width: '90%',margin:"20px" ,backgroundColor:"#eff0f7" }}>
      <Card.Body>
      
      <NavLink style={{textDecoration:"none"}} to={`/current-orders`}><Card.Subtitle style={{textAlign:"center"}} className="mb-2 text-muted">Current Ordes</Card.Subtitle></NavLink>
     

      </Card.Body>
    </Card>
         <Card style={{ width: '90%',margin:"20px",backgroundColor:"#eff0f7"  }}>
      <Card.Body>
      
      <Card.Subtitle style={{textAlign:"center"}} className="mb-2 text-muted">Order Completed</Card.Subtitle>
     

      </Card.Body>
    </Card>
         <Card style={{ width: '90%',margin:"20px",backgroundColor:"#eff0f7"  }}>
      <Card.Body>
      
      <NavLink style={{textDecoration:"none"}} to={'/uploadmenu'}>   <Card.Subtitle style={{textAlign:"center"}} className="mb-2 text-muted">Add/Update Menu</Card.Subtitle></NavLink> 
     

      </Card.Body>
    </Card>
         <Card style={{ width: '90%',margin:"20px" ,backgroundColor:"#eff0f7" }}>
      <Card.Body>
      
      <Card.Subtitle style={{textAlign:"center"}} className="mb-2 text-muted">Reports </Card.Subtitle>
     

      </Card.Body>
    </Card>
         <Card style={{ width: '90%',margin:"20px" ,backgroundColor:"#eff0f7" }}>
      <Card.Body>
      
      <NavLink style={{textDecoration:"none"}} to={'/daily-dash'}>   <Card.Subtitle style={{textAlign:"center"}} className="mb-2 text-muted">Dashboard</Card.Subtitle></NavLink> 
     

      </Card.Body>
    </Card>
   
    </div>
  )
}

export default BusinessDashboard;
