import React, { useEffect, useState,useContext } from "react";
import "./getbusiness.css";
import { useNavigate } from "react-router-dom";
import { IoMdArrowDropright } from "react-icons/io";
import Card from 'react-bootstrap/Card';
import axios from "axios";
import { CartContext } from "../context/CartContext";


const GetBusiness = () => {
  const navigate = useNavigate();
  const [business, setbusiness] = useState([]);
  const { addItem, cartcount,   clearCart,businessName,updateBusinessName } = useContext(CartContext);
  

  const handleBusinessClick=(item)=>{
    console.log(item);
    if(localStorage.getItem('businessId') != item.id){
    
      clearCart();
    }
    localStorage.setItem('businessId',item.id);
    updateBusinessName(item.name);
   
   
    navigate('/business_dashboard');
  }
  useEffect(() => {
    const tokenId = localStorage.getItem("tokenId")
    const id = localStorage.getItem('id');
    const listbusiness = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}api/users/${id}/businesses`, {
            headers: {
              "Authorization": `Bearer ${tokenId}`
            }
          }
        );
        const data = response.data;
       console.log(data);
        setbusiness(data);
      } catch (error) {
        console.error("Error fetching business:", error);
      }
    };
    listbusiness();
  }, []);

  return (
    <div>
      <div className="business-main">
      
     {business.length === 0 ? ( <Card style={{ width: '20rem',margin:"20px" }}>
      <Card.Body>
        <Card.Title>No Business To Show</Card.Title>
     
        <Card.Text>
         Please click on "Add New Business" to add your business .
        </Card.Text>
 
      </Card.Body>
    </Card>) : (<>{business.map((item, index) => {
        return (
          <div className="business_details" key={item.id} onClick={()=>handleBusinessClick(item)}>
            {/* <h1>{item.logo}</h1> */}
            <p>
              name: - <span>{item.name}</span>
            </p>
            <p>
              Location: - <span>{item.address}</span>
            </p>
            <p>
              email: - <span>{item.email}</span>
            </p>
            <p>
              phone no: - <span>{item.phoneNumber}</span>
            </p>
          </div>
        );
      })}</>)}
      </div>
      <div className="business_footer"  onClick={() => navigate("/upload")}>
     
          <h3>Add New Business</h3>
          <h3><IoMdArrowDropright/></h3>
      </div>
    </div>
  );
};

export default GetBusiness;
