import React, { useState, useEffect } from "react";
import "./home.css";
import CryptoJS from 'crypto-js';
import { signInWithPopup, onAuthStateChanged } from "firebase/auth";
import { auth, provider } from "../Config/config";
import { NavLink, useNavigate } from "react-router-dom";
import Footer from "./Footer";
import axios from "axios";
import GridLoader from "react-spinners/GridLoader"; // Import GridLoader

const Home = () => {
  const [value, setValue] = useState("");
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false); // Loading state for GridLoader

  const encryptionKey = 'hello guys';

  const saveEncryptedEmailToLocalstorage = (email) => {
    const encryptedEmail = CryptoJS.AES.encrypt(email, encryptionKey).toString();
    localStorage.setItem('encryptedEmail', encryptedEmail);
  };

  const openHandle = () => {
    setOpen(!open);
  };

  const handleClickHome = async () => {
    setLoading(true); // Set loading to true when the sign-in process starts
    let data;
    try {
      data = await signInWithPopup(auth, provider);
      const { email, displayName: username } = data.user;
      const { idToken: tokenId } = data._tokenResponse;

      setValue(email);
      saveEncryptedEmailToLocalstorage(email);
      localStorage.setItem('tokenId', tokenId);

      console.log(data);
    } catch (error) {
      console.error("Error signing in: ", error);
      setLoading(false); // Stop loading if sign-in fails
      return; // Exit the function if sign-in fails
    }

    try {
      const { email, displayName: username } = data.user;
      const response = await axios.post(`${process.env.REACT_APP_API_URL}api/users`, { email, username });
      console.log(response);
      const id = response.data.id;
      console.log(id);
      localStorage.setItem('id', id);
      navigate('/getbusiness');
    } catch (error) {
      console.error("Error calling app/users: ", error);
    } finally {
      setLoading(false); // Stop loading after the process completes
    }
  };

  return (
    <>
      <div className="home_main">
        <div className="main">
          <section id="header" className="sections">
            <div className="containers">
              <div className="main_container">
                <div className="main_text_home">
                  <h1>Tap & Order </h1>
                  <h4 className="my-3">
                  Tap & Order is a cutting-edge food ordering app designed for restaurants to streamline operations and enhance customer service. Easily manage menus, update item details, and handle orders with a user-friendly interface. Integrate TastyTap to provide a modern, efficient dining experience for your customers while optimizing your workflow.
                  </h4>
                  <div className="mt-3">
                    <NavLink onClick={handleClickHome} className="btn-submit">
                      Login to continue.
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      {loading && (
        <div className="loader-overlay">
          <GridLoader size={50} color="black" />
        </div>
      )}
      <Footer />
    </>
  );
};

export default Home;
