

import React, { useRef, useState } from "react";
import axios from "axios";
import { GridLoader } from "react-spinners"; // Import GridLoader from react-spinners

import "./upload.css";
import { useNavigate } from "react-router-dom";

const Upload = () => {
  const [files, setFiles] = useState([]);
  const [logo, setLogo] = useState();
  const [businessName, setBusinessName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [loading, setLoading] = useState(false); // Loading state for form submission
  const fileInputRef = useRef(null);
  const logoInputRef = useRef(null);
  const navigate = useNavigate();

  // Input change handlers
  const handleFileChange = (e) => {
    setFiles([...e.target.files]);
  };

  const handleLogoChange = (e) => {
    setLogo(e.target.files[0]);
  };

  // Submit handler
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading state to true before making the request

    const id = localStorage.getItem("id");
    const tokenId = localStorage.getItem("tokenId");
    const formData = new FormData();
    formData.append("name", businessName);
    formData.append("email", email);
    formData.append("phoneNumber", phoneNumber);
    formData.append("address", address);

    if (logo) {
      formData.append("logo", logo);
    }

    files.forEach((file) => {
      formData.append("files", file);
    });

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/users/${id}/businesses`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${tokenId}`,
          },
        }
      );

      if (response.status === 200) {
        console.log("Files and data uploaded successfully");
        navigate("/getbusiness");
      } else {
        console.error("Failed to upload files and data");
      }
    } catch (error) {
      console.error("Error uploading files and data:", error);
    } finally {
      setLoading(false); // Reset loading state after request completes
    }
  };

  return (
    <div className="main-div">
      <form className="form" onSubmit={handleSubmit}>
        <div className="main-text">
          <h1 style={{ marginTop: ".5rem" }}>Add Your Business</h1>
          <div>
            <div className="form-group">
              <label htmlFor="businessName">Business Name</label>
              <input
                type="text"
                className="form-control"
                id="businessName"
                placeholder="XYZ pvt. ltd."
                value={businessName}
                onChange={(e) => setBusinessName(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="phoneNumber">Phone Number</label>
              <input
                type="number"
                className="form-control"
                id="phoneNumber"
                placeholder="9999999999"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="address">Address</label>
              <input
                type="text"
                className="form-control"
                id="address"
                placeholder="jabalpur,Mp,india"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="logoFile" className="form-label">
                Upload Business Logo
              </label>
              <input
                accept="image/*"
                ref={logoInputRef}
                className="form-control"
                type="file"
                id="logoFile"
                onChange={handleLogoChange}
              />
            </div>
          </div>
          <div style={{ paddingTop: "1rem" }}>
            <button className="btn-submit" type="submit">
              Submit
            </button>
          </div>

          {/* Conditionally render GridLoader based on loading state */}
          {loading && (
            <div className="loader-overlay">
              <GridLoader size={50} color="black" />
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default Upload; // Ensure default export
