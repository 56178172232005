import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FaRupeeSign } from 'react-icons/fa';
import { IoMdArrowDropright } from 'react-icons/io';
import GridLoader from 'react-spinners/GridLoader'; // Import GridLoader
import logo from '../datas/foodlogo.jpg'; // Placeholder for item images

const UserMenu = () => {
  const [menu, setMenu] = useState([]);
  const [activeCategory, setActiveCategory] = useState(0);
  const [loading, setLoading] = useState(true); // Loading state for fetching data
  const navigate = useNavigate();

  const categoryRefs = useRef([]);
  const itemRefs = useRef([]);
  const sliderRef = useRef(null);

  useEffect(() => {
    const fetchMenu = async () => {
      const tokenId = localStorage.getItem('tokenId');
      const businessId = localStorage.getItem('businessId');
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}api/businesses/${businessId}/categories/with-items`, 
          {
            headers: {
              "Authorization": `Bearer ${tokenId}`
            }
          }
        );
        const data = response.data;
        setMenu(data);
      } catch (error) {
        console.error("Error fetching menu:", error);
      } finally {
        setLoading(false); // Update loading state after request completes
      }
    };
    
    fetchMenu();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const categoryElements = categoryRefs.current;
      categoryElements.forEach((categoryElement, index) => {
        const rect = categoryElement.getBoundingClientRect();
        if (rect.top <= window.innerHeight / 2 && rect.bottom >= window.innerHeight / 2) {
          setActiveCategory(index);
          scrollSliderToActiveCategory(index);
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollSliderToActiveCategory = (index) => {
    const sliderElement = sliderRef.current;
    const activeButton = sliderElement.children[index];
    const offset = activeButton.offsetLeft - sliderElement.offsetWidth / 2 + activeButton.offsetWidth / 2;
    sliderElement.scrollTo({ left: offset, behavior: 'smooth' });
  };

  const scrollToCategory = (index) => {
    const categoryElement = categoryRefs.current[index];
    const firstItemElement = itemRefs.current[index] && itemRefs.current[index][0];
    if (categoryElement && firstItemElement) {
      window.scrollTo({ top: firstItemElement.offsetTop - 100, behavior: "smooth" });
      setActiveCategory(index);
      scrollSliderToActiveCategory(index);
    }
  };

  if (loading) {
    return (
      <div className="loader-overlay">
        <GridLoader size={50} color="black" />
      </div>
    );
  }

  return (
    <div>
      <div className="menu_main">
        {menu.map((category, index) => (
          <div
            key={index}
            className="menu_category"
            ref={el => categoryRefs.current[index] = el}
          >
            {category.items.map((item, itemIndex) => (
              <div
                key={item.id}
                className={`menu_item`}
                ref={el => {
                  if (!itemRefs.current[index]) itemRefs.current[index] = [];
                  itemRefs.current[index][itemIndex] = el;
                }}
              >
                <div className="menu_desc">
                  <p>{item.name}</p>
                  <div className="btnprice">
                    <h5>
                      <FaRupeeSign />
                      {item.price}
                    </h5>
                  </div>
                </div>
                <div>
                  <img className="menu_image" src={logo} alt={item.name} />
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
      <div className="horizontal_slider" ref={sliderRef}>
        {menu.map((category, index) => (
          <button
            key={index}
            className={`slider_button ${activeCategory === index ? "active" : ""}`}
            onClick={() => scrollToCategory(index)}
          >
            {category.category.name}
          </button>
        ))}
      </div>
      <div className="menu_footer" onClick={() => navigate("/uploadmenu")}>
        <h3>
          Add OR Replace Menu{" "}
        </h3>
        <h3><IoMdArrowDropright /></h3>
      </div>
    </div>
  );
};

export default UserMenu;


// import React, { useEffect, useState } from "react";
// import "./usermenu.css";
// import axios from "axios";
// import { MdDeleteForever, MdEdit, MdOutlineSaveAlt } from "react-icons/md";
// import { GridLoader } from "react-spinners";
// import { useLocation, useNavigate } from "react-router-dom";

// const UserMenu = () => {
//   const [items, setItems] = useState([]);

//   const [loading, setLoading] = useState(false);
//   const [updateId, setUpdateId] = useState(null);
//   const [expense, setExpense] = useState(false);
//   const [currentPage, setCurrentPage] = useState(1);
//   const navigate = useNavigate();

//   //data from getbusiness
  

//   useEffect(() => {
//     // Fetch items when component mounts
//     getItems();
//   }, []);



//   // Pagination
//   const itemsPerPage = 30;
//   const indexOfLastItem = currentPage * itemsPerPage;
//   const indexOfFirstItem = indexOfLastItem - itemsPerPage + 1; // Adjust the starting index
//   const currentItems = items.slice(indexOfFirstItem - 1, indexOfLastItem); // Adjust slice parameters
//   const paginate = (pageNumber) => setCurrentPage(pageNumber);

//   console.log(expense);

//   // -----------------------------------------------------------------------------
//   const toggleEdit = (id) => {
//     setUpdateId(id);
//   };

//   const getItems = async () => {
//     const tokenId = localStorage.getItem("tokenId");
//     const businessId = localStorage.getItem('businessId');
//     setLoading(true);
//     try {
//       const response = await axios.get(`${process.env.REACT_APP_API_URL}api/businesses/${businessId}/categories/with-items`, {
//         headers: {
//           "Authorization": `Bearer ${tokenId}`
//         }
//       });
//       console.log(response.data);
//       setItems(response.data);
//     } catch (error) {
//       console.error(error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const clickupload = ()=>{
 
//     navigate("/uploadmenu")
//   }

//   return (
//     <>
//       {loading && (
//         <div className="loader-overlay">
//           <GridLoader size={50} color="black" />
//         </div>
//       )}
//       <div className={`add-items ${loading ? 'blur' : ''}`}>
//         <div className="container table-responsive py-5" style={{backgroundColor:"#f9f5f0"}}>
//           <h3>Menu Items</h3>
//           <ol>
//             {items.map((categoryData, categoryIndex) => (
//               <li key={categoryData.category.id}>
//                 <h2 className="categoryname">{categoryData.category.name}</h2>
//                 {categoryData.items.length > 0 ? (
//                   <ol>
//                     {categoryData.items.map((item, itemIndex) => (
//                       <li key={item.id}>
//                         <h3>{item.name}</h3>
//                         <p>Price: ${item.price}</p>
//                       </li>
//                     ))}
//                   </ol>
//                 ) : (
//                   <p>No items available in this category</p>
//                 )}
//               </li>
//             ))}
//           </ol>
//           {/* Pagination */}
//           <ul className="pagination">
//             {Array.from({ length: Math.ceil(items.length / itemsPerPage) }, (_, i) => (
//               <li key={i} className="page-item">
//                 <button onClick={() => paginate(i + 1)} className="page-link">
//                   {i + 1}
//                 </button>
//               </li>
//             ))}
//           </ul>
//         </div>
//         <div className="usermenu_footer"  onClick={clickupload}>
    
//             <h3>Add Or Replace Menu</h3>
        
//         </div>
//       </div>
//     </>
//   );
// };

// export default UserMenu;
