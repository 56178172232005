import React, { useState, useEffect,useRef } from "react";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import "./dashboard.css";
import { Chart } from "react-google-charts";
import { NavLink, useNavigate } from "react-router-dom";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";

import axios from "axios";

const DailyDash = () => {
  const [chartData, setChartData] = useState([]);
  const [pieChartData, setPieChartData] = useState([["Item", "Count"]]);
  const [orderItemCountData, setOrderItemCountData] = useState([["Order ID", "Item Count"]]);
  const [totalSalePrice, setTotalSalePrice] = useState(0);
  const [totalItemsSold, setTotalItemsSold] = useState(0);
  const navigate = useNavigate()


  useEffect(() => {
    const fetchData = async () => {
      const bId = localStorage.getItem("businessId");
      const currentDate = new Date();
      const formattedDate = currentDate.toISOString().split('T')[0];

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}orders/business/${bId}/date/${formattedDate}`
        );
        const data = response.data;

        const itemCounts = {};
        const lineChartData = [];
        const orderItemCounts = {};
        let overallSalePriceSum = 0;
        let overallItemsCount = 0;

        data.forEach((order) => {
          let itemCount = 0;

          order.orderDetailItems.forEach((itemDetail) => {
            const itemName = itemDetail.item.name;
            const itemPrice = itemDetail.price;
            const itemQuantity = itemDetail.quantity;
            itemCount += itemQuantity;
            overallSalePriceSum += itemPrice * itemQuantity;
            overallItemsCount += itemQuantity;

            if (itemCounts[itemName]) {
              itemCounts[itemName] += itemQuantity;
            } else {
              itemCounts[itemName] = itemQuantity;
            }

            lineChartData.push({
              name: itemName,
              price: itemPrice,
            });
          });

          orderItemCounts[order.id] = itemCount;
        });

        const formattedPieData = [["Item", "Count"]];
        Object.keys(itemCounts).forEach((key) => {
          formattedPieData.push([key, itemCounts[key]]);
        });

        const formattedOrderItemCountData = [["Order ID", "Item Count"]];
        Object.keys(orderItemCounts).forEach((orderId) => {
          formattedOrderItemCountData.push([orderId, orderItemCounts[orderId]]);
        });

        setChartData(lineChartData);
        setPieChartData(formattedPieData);
        setOrderItemCountData(formattedOrderItemCountData);
        setTotalSalePrice(overallSalePriceSum);
        setTotalItemsSold(overallItemsCount);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const datewiseData = ()=>{
    navigate("/date-dash")
  }

  return (
    <div>
        <div style={{display:"flex",justifyContent:"flex-end"}}>
       <NavLink style={{width:"100vw",color:"white",position:"relative",backgroundColor:"	#36454F",textAlign:"center",padding:"5px",paddingTop:'20px'}} to={"/date-dash"}>Date Wise Sale Info <MdKeyboardDoubleArrowRight /></NavLink>
       </div>
      <div className="af_main">
        <div
          className="card"
          style={{
            width: "16rem",
            marginTop: "1.5rem",
            backgroundColor: "#94c875",
            color: "white",
          }}
        >
          <div className="card-body">
            <h4 className="card-text">Total Sale Price</h4>
            <h1 className="card-title">{totalSalePrice}</h1>
          </div>
        </div>
        <div
          className="card"
          style={{
            width: "16rem",
            marginTop: "1.5rem",
            backgroundColor: "#dd3f5b",
            color: "white",
          }}
        >
          <div className="card-body">
            <h4 className="card-text">Total Items Sold </h4>
            <h1 className="card-title">{totalItemsSold}</h1>
          </div>
        </div>
      </div>

      <div style={{
          width: "90%",
          padding: "1rem",
          margin: "1.5rem",
          backgroundColor: "iceblue",
          border: "1px solid black",
          borderRadius: "10px",
        }}>
        <h2>Line Chart</h2>
        <div div style={{ overflowX: "scroll" }}>
        <ResponsiveContainer width="100%" height={400}>
          <LineChart
            data={chartData}
            margin={{
              top: 5, right: 30, left: 20, bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="price" stroke="#8884d8" activeDot={{ r: 8 }} />
          </LineChart>
        </ResponsiveContainer>
        </div>
      </div>
      <div style={{
          width: "90%",
          padding: "1rem",
          margin: "1.5rem",
          backgroundColor: "iceblue",
          border: "1px solid black",
          borderRadius: "10px",
        }}>
        <h2>Pie Chart - Item Counts</h2>
        <Chart
          width={'100%'}
          height={'400px'}
          chartType="PieChart"
          loader={<div>Loading Chart</div>}
          data={pieChartData}
          options={{
            title: 'Item Counts per ID',
          }}
        />
      </div>
      <div style={{
          width: "90%",
          padding: "1rem",
          margin: "1.5rem",
          backgroundColor: "iceblue",
          border: "1px solid black",
          borderRadius: "10px",
        }}>
        <h2>Pie Chart - Order Item Counts</h2>
        <Chart
          width={'100%'}
          height={'400px'}
          chartType="PieChart"
          loader={<div>Loading Chart</div>}
          data={orderItemCountData}
          options={{
            title: 'Item Counts per Order ID',
          }}
        />
      </div>
    </div>
  );
};

export default DailyDash;
