import React, { useContext, useEffect, useState } from 'react';
import './checkoutResponse.css';
import confetti from 'canvas-confetti';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { LuIndianRupee } from "react-icons/lu";
import {CartContext} from '../context/CartContext';
const CheckoutResponse = () => {
    const { items, addItem, removeItem, totalPrice, clearCart} = useContext(CartContext);
  const { state } = useLocation();
  const { data } = state || {};
  const { total ,cgst,sgst,orderNumber ,BusinessName} = state || {};
  const navigate = useNavigate();
 


  //bomb animation canvs confetti
  var duration = 2 * 1000;
  var animationEnd = Date.now() + duration;
  var defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };
  
  function randomInRange(min, max) {
    return Math.random() * (max - min) + min;
  }
  
  var interval = setInterval(function() {
    var timeLeft = animationEnd - Date.now();
  
    if (timeLeft <= 0) {
      return clearInterval(interval);
    }
  
    var particleCount = 200 * (timeLeft / duration);
    // since particles fall down, start a bit higher than random
    confetti({ ...defaults, particleCount, origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 } });
    confetti({ ...defaults, particleCount, origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 } });
  }, 250);
  useEffect(()=>{
    setTimeout(interval, 0);
  },[])

  // click handler
  const clickHandler = ()=>{
    const id = localStorage.getItem("businessId");
//   localStorage.setItem("cartItems",[]);
//   localStorage.setItem("cartCount","0");
  clearCart();
  navigate(`/business/${id}`);
  }

  return (
    <div className='crmain'>
     
    

        <h3 style={{textAlign:"center"}}>{BusinessName}</h3>
        <div className='crheading'>
        <h6>Order Summary</h6>
      </div>
      <div style={{margin:"10px 10px 20px 10px",borderBottom:"1px solid gainsboro"}}>
        <p><b>Order</b> # - {orderNumber}</p>
        <p><b>Created Time</b> - 18/05/2024</p>

      </div>
    
      <div>
        {data && data.length > 0 ? (
          data.map((order) => (
            <div key={order.id} className="order-item">
              <h6>{order.item.name}</h6>
              <div className='crpricetotal'>
                <p>
                  <LuIndianRupee/>{order.item.price} x {order.quantity}
                </p>
                <p>
                  Total: <LuIndianRupee/>{order.item.price*order.quantity}
                </p>
              </div>
            </div>
          ))
        ) : (
          <p>No order data available.</p>
        )}
      </div>
      <div className='totla-and-taxes'>
        <div className='totaltaxes'>
        <h6>Item Total</h6>
        <h6><LuIndianRupee/>{total}</h6>
        </div>
        <div className='totaltaxes'>
        <h5>cgst</h5>
        <p><LuIndianRupee/>{cgst}</p>
        </div>
        <div className='totaltaxes'>
        <h5>sgst</h5>
        <p><LuIndianRupee/>{sgst}</p>
        </div>
    
        <div className='totaltaxes'>
        <h5>Grand Total</h5>
        <h5><LuIndianRupee/>{total+sgst+cgst}</h5>
        </div>
      </div>
      <button className='goback' onClick={clickHandler} >
       
          <h2>Go Back To Menu</h2>
     
      </ button>
      <button className='goback'  >
       
          <h2><NavLink to={"/fs"}>Play Game</NavLink>   </h2>
     
      </ button>
    </div>
  );
};

export default CheckoutResponse;
